import React from 'react';

const IconsSprites = () => (
  <svg style={{ display: 'none' }}>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" id="cross">
      <path
        d="M13.8197 12.9385C14.0601 13.1788 14.0601 13.5693 13.8197 13.8197C13.6996 13.9399 13.5394 14 13.3791 14C13.2189 14 13.0587 13.9399 12.9385 13.8197C10.545 11.4563 8.5923 9.52359 7.01002 7.94131C0.981384 14 0.901252 14 0.620883 14C0.280369 14 0 13.7196 0 13.3791C0 13.1688 0.100119 12.9785 0.270406 12.8684C0.610859 12.5679 3.12446 10.0544 6.10871 7.05009C0 0.981441 0 0.911333 0 0.630904C0 0.280429 0.280369 0 0.620883 0C0.841169 0 1.02148 0.110142 1.14165 0.270405C1.44206 0.610856 3.97566 3.15452 7 6.1588C9.00286 4.14593 11.1359 2.00286 12.9285 0.190274C13.1688 -0.0600832 13.5694 -0.0600832 13.8197 0.18025C14.0601 0.430607 14.0601 0.821178 13.8197 1.07153C11.4263 3.48501 9.48353 5.44777 7.90131 7.05009C9.89415 9.05295 12.0272 11.1659 13.8197 12.9385Z">
      </path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" id="lens">
      <path
        d="M15.8112 14.9301C14.8912 14.02 12.741 11.87 11.5109 10.65C12.441 9.52002 13.001 8.08002 13.001 6.5C13.001 2.91003 10.0908 0 6.5005 0C2.9102 0 0 2.91003 0 6.5C0 10.09 2.9102 13 6.5005 13C8.07063 13 9.50073 12.45 10.6208 11.53C11.6709 12.58 13.671 14.56 14.9412 15.82C15.0612 15.94 15.2211 16 15.3812 16C15.5412 16 15.7012 15.94 15.8212 15.82C16.0612 15.57 16.0612 15.1801 15.8112 14.9301ZM6.5005 11.75C3.61026 11.75 1.2501 9.40002 1.2501 6.5C1.2501 3.61005 3.61026 1.25 6.5005 1.25C9.39074 1.25 11.7509 3.61005 11.7509 6.5C11.7509 9.40002 9.39074 11.75 6.5005 11.75Z">
      </path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" id="location">
      <path
        d="M7.11 1.25C10.1632 1.25 12.6934 3.70254 12.75 6.69355C12.75 10.4599 8.17448 13.846 6.99982 14.653C5.83436 13.8529 1.24997 10.4665 1.24978 6.71712C1.30663 3.70254 3.83682 1.25 6.89 1.25H7.11ZM7.11 0H6.89C3.15 0 0.07 2.98158 0 6.69355C0 11.6762 6.33 15.7183 6.6 15.8884C6.70788 15.9604 6.84 16 6.96005 16C6.97353 16 6.98687 15.9995 7 15.9985C7.15 15.9985 7.29 15.9585 7.4 15.8884C7.67 15.7183 14 11.6762 14 6.69355C13.93 2.98158 10.85 0 7.11 0ZM7.00411 5.26342C7.88651 5.26992 8.6039 5.98203 8.60392 6.84532C8.59908 7.71574 7.87152 8.42387 6.98893 8.42389C6.10513 8.41903 5.3861 7.7024 5.38608 6.83336C5.39086 5.97731 6.10528 5.273 6.96482 5.26342H7.00411ZM7.00794 4.01342H6.96482C5.40385 4.03068 4.14473 5.28184 4.1361 6.82638C4.1361 8.38818 5.41247 9.66525 6.98206 9.67387C8.56028 9.67387 9.84527 8.40543 9.8539 6.85227C9.8539 5.29048 8.57753 4.02206 7.00794 4.01342Z">
      </path>
    </symbol>
    <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 14" id="next">
      <path
        d="M1.07445 0.179608L7.81487 6.58053C8.06171 6.81494 8.06171 7.19499 7.81487 7.4294L1.07445 13.8304C0.948365 13.9401 0.790721 14 0.633077 14C0.464979 14 0.307335 13.9401 0.181246 13.8304C-0.0604152 13.5909 -0.0604152 13.2117 0.181246 12.9822L6.48598 7.00496L0.181246 1.01778C-0.0604152 0.788291 -0.0604152 0.409097 0.181246 0.179608C0.433425 -0.0598694 0.83273 -0.0598694 1.07445 0.179608Z">
      </path>
    </symbol>
  </svg>
);

export default IconsSprites;
