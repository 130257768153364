import React from 'react';

type FormErrorProps = {
  message?: string;
  icon?: React.ReactNode | null;
};

export default function FormError({ message, icon }: FormErrorProps) {
  return (
    <div className="flex flex-row gap-1 items-start self-stretch mt-2">
      <div className="shrink-0">{icon}</div>
      <p className="text-xs text-error ">{message}</p>
    </div>
  );
}
