'use client';

import { cn } from '@/helpers/common';
import { useClickOutside } from '@/hooks/useClickOutside';
import React, { Dispatch, FormEvent, SetStateAction, useRef, useState } from 'react';
import FormError from '../common/form-error';

interface SearchFieldProps {
  icon: string;
  name: string;
  id: string;
  placeholder: string;
  maxLength: number;
  minLength: number;
  value?: string;
  onChange: (e: FormEvent) => void;
  onSuggestionsClick: (text: string) => void;
  isSuggestionsOpened: boolean;
  setIsSuggestionsOpened: Dispatch<SetStateAction<boolean>>;
  cancelSearch?: () => void;
  showCancel?: boolean;
  suggestions?: Array<{ title: string }>;
  className?: string;
  disabled?: boolean;
  showLeftIcon?: boolean;
  classNames?: {
    input?: string;
    suggestions?: string;
    suggestionElement?: string;
    suggestionElementActive?: string;
    keywordInputClassName?: string;
    locationInputClassName?: string;
  };
  errorMessage?: string;
  isInvalid?: boolean;
  autoFocus?: boolean;
}

const SearchField: React.FC<SearchFieldProps> = ({
  icon,
  name,
  id,
  placeholder,
  maxLength,
  minLength,
  value = '',
  onChange,
  onSuggestionsClick,
  cancelSearch,
  showCancel = true,
  suggestions = [],
  isSuggestionsOpened,
  setIsSuggestionsOpened,
  className = '',
  disabled = false,
  showLeftIcon = true,
  classNames,
  errorMessage,
  isInvalid,
  autoFocus = false,
}) => {
  const [focusedItemIdx, setFocusedItemIdx] = useState(0);
  const searchRef = useRef<HTMLDivElement | null>(null);

  const selectSuggestion = (text: string) => {
    setIsSuggestionsOpened(false);
    setFocusedItemIdx(0);
    onSuggestionsClick(text);
  };

  const handleKeyDown = (event: any) => {
    if (event.repeat) {
      return;
    }

    if (isSuggestionsOpened && suggestions.length > 0) {
      const lastKeyPressed = event.key;

      if (event.key === 'Escape' || event.key === 'Tab') {
        setIsSuggestionsOpened(false);
        return;
      }

      if (event.key === 'Enter') {
        const focusedItem = suggestions[focusedItemIdx];

        if (!focusedItem) {
          return;
        }

        event.preventDefault();
        event.stopPropagation();
        selectSuggestion(typeof focusedItem === 'string' ? focusedItem : focusedItem?.title);
      }

      const isFirstOneWithUpClick = lastKeyPressed === 'ArrowUp' && focusedItemIdx === 0;
      const isLastOneWithDownClick =
        lastKeyPressed === 'ArrowDown' && focusedItemIdx === suggestions.length - 1;
      const isDownClickByList =
        lastKeyPressed === 'ArrowDown' &&
        focusedItemIdx >= 0 &&
        focusedItemIdx < suggestions.length - 1;
      const isUpClickByList =
        lastKeyPressed === 'ArrowUp' &&
        focusedItemIdx > 0 &&
        focusedItemIdx <= suggestions.length - 1;

      if (isDownClickByList) {
        event.preventDefault();
        setFocusedItemIdx((prev) => prev + 1);
      }

      if (isLastOneWithDownClick) {
        event.preventDefault();
        setFocusedItemIdx(0);
      }

      if (isFirstOneWithUpClick) {
        event.preventDefault();
        setFocusedItemIdx(suggestions.length - 1);
      }

      if (isUpClickByList) {
        event.preventDefault();
        setFocusedItemIdx((prev) => prev - 1);
      }
    }
  };

  useClickOutside(
    searchRef as React.RefObject<HTMLElement>,
    () => {
      setIsSuggestionsOpened(false);
      setFocusedItemIdx(0);
    },
    {
      skip: !isSuggestionsOpened,
    },
  );

  return (
    <div
      key={`input-${name}`}
      ref={searchRef}
      className={cn('relative flex items-center', className)}
    >
      <div
        className={cn(
          'hidden absolute w-full z-10 top-[calc(100%+10px)] py-2 bg-white',
          classNames?.suggestions,
          {
            '!block': isSuggestionsOpened && suggestions.length > 0,
          },
        )}
      >
        {suggestions.map((el, index) => (
          <div
            key={typeof el === 'string' ? el : el?.title}
            role="button"
            tabIndex={0}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              selectSuggestion(typeof el === 'string' ? el : el?.title);
            }}
            className={cn(
              'flex items-center px-3 py-1 z-10 default-transition',
              classNames?.suggestionElement,
              focusedItemIdx === index ? classNames?.suggestionElementActive : '',
            )}
          >
            {typeof el === 'string' ? el : el?.title}
          </div>
        ))}
      </div>
      {showLeftIcon ? (
        <span className="absolute z-[4] left-3">
          <svg
            className={cn('fill-dove-gray align-middle', name === 'l' ? 'w-[14px] h-4' : 'w-4 h-4')}
          >
            <use xlinkHref={icon}></use>
          </svg>
        </span>
      ) : null}
      <input
        className={cn(
          `relative w-full lg:max-w-[100%] py-[9px] px-10 bg-secondary border max-md:text-base md:text-sm rounded-3xl default-transition`,
          name === 'l'
            ? 'lg:w-[220px] lg:rounded-l lg:rounded-r-3xl'
            : 'lg:w-[440px] lg:rounded-r lg:rounded-l-3xl',
          classNames?.input,
          name === 'l' ? classNames?.locationInputClassName : classNames?.keywordInputClassName,
          { 'pl-4': !showLeftIcon },
        )}
        type="text"
        name={name}
        id={id}
        placeholder={placeholder}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChange(e);
          setIsSuggestionsOpened(true);
        }}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        autoComplete="off"
        autoFocus={autoFocus}
        onFocus={() => setIsSuggestionsOpened(true)}
      />
      {isInvalid && <FormError message={errorMessage} />}
      {value && showCancel && (
        <span onClick={cancelSearch} className=" absolute z-[4] right-3 cursor-pointer">
          <svg className="w-[14px] h-[14px] fill-dove-gray align-middle">
            <use xlinkHref="#cross"></use>
          </svg>
        </span>
      )}
    </div>
  );
};

export default SearchField;
